import _assignIn from 'lodash/assignIn';
import _castArray from 'lodash/castArray';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import _isObject from 'lodash/isObject';
import _mergeWith from 'lodash/mergeWith';
import _pick from 'lodash/pick';
import _transform from 'lodash/transform';
import _union from 'lodash/union';

import { toast } from 'react-toastify';
import { flatten, unflatten } from './utils';

/**
 * MAP A graphql object to remove all occurrences of the __typename
 * Another way to do it
 * https://stackoverflow.com/questions/42631523/remove-unnecessary-fields-before-mutation-in-graphql
 * Seems conceptually wrong
 *
 * @param {object} graphqlObject
 * @returns {object}
 */
export const cleanGraph = (graphqlObject) => {
  if (graphqlObject) {
    let keys = Object.keys(graphqlObject);
    delete graphqlObject.__typename;
    keys.forEach(key => {
      if (Array.isArray(graphqlObject[key])) {
        graphqlObject[key].forEach(sub => cleanGraph(sub));
      } else if (typeof graphqlObject[key] === 'object') {
        graphqlObject[key] && delete graphqlObject[key].__typename;
        cleanGraph(graphqlObject[key]);
      }
    });
  }
  return graphqlObject;
};

/**
 * @param {string} queryName
 * @param {function} fetchMore
 * @param {number} skip
 * @returns {Promise<boolean>}
 */
export const loadMoreFromQuery = (queryName, fetchMore, skip) => new Promise((resolve) => {
  fetchMore({
    variables: { skip },
    updateQuery: (prev = {}, { fetchMoreResult }) => {
      if (!fetchMoreResult) {
        resolve(false);
        return prev;
      }
      
      if (!fetchMoreResult[queryName].length) {
        resolve(false);
        return prev;
      }
      
      resolve(true);
      return {
        ...prev,
        ...{
          [queryName]: [
            ...(prev[queryName] || []),
            ...(fetchMoreResult[queryName] || []),
          ],
        },
      };
    },
  });
});

/**
 * @param {string} queryName
 * @param {function} fetchMore
 * @param {number} page
 * @returns {Promise<boolean>}
 */
export const loadMoreFromPaginationQuery = (queryName, fetchMore, page) => new Promise((resolve) => {
  fetchMore({
    variables: { page },
    updateQuery: (prev = {}, options = {}) => {
      const { fetchMoreResult } = options;
      
      if (!fetchMoreResult) {
        resolve(false);
        return prev;
      }
      
      const _currentValue = _get(fetchMoreResult, [queryName]);
      const _currentItems = _get(_currentValue, 'items', []);
      
      if (!_currentItems.length) {
        resolve(false);
        return prev;
      }
      
      resolve(true);
      
      const _prevValues = _get(prev, [queryName], {});
      const _prevItems = _get(_prevValues, 'items', []);
      
      return {
        ...prev,
        ...{
          [queryName]: {
            ..._currentValue,
            items: [..._prevItems, ..._currentItems],
          },
        },
      };
    },
  });
});

export const onCatchError = (error) => {
  let networkError = _get(error, 'networkError.reult.errors', []);
  let graphQLErrors = _get(error, 'graphQLErrors', []);
  if (networkError.length > 0)
    networkError.map(({ message }) => toast.error(message));
  if (graphQLErrors.length > 0)
    graphQLErrors.forEach(({ message }) => toast.error(message));
  else
    toast.error('An error occurred while processing the request. Try it later');
  return { error };
};

export const updateCachedObject = (cacheObject, newObject) => {
  return _mergeWith(
    cacheObject,
    newObject,
    (cacheValue, newValue) => {
      if (_isArray(cacheValue)) return _castArray(newValue);
    }
  );
};

export const updateCachedObject2 = (cachedObject, newObject) => {
  let flattenCache = flatten(cachedObject);
  let flattenNew = flatten(newObject);
  
  const modifiedCacheKeys = Object
    .keys(flattenCache)
    .filter(key => !_isEqual(flattenCache[key], flattenNew[key]));
  
  const modifiedNewKeys = Object
    .keys(flattenNew)
    .filter(key => !_isEqual(flattenNew[key], flattenCache[key]));
  
  const allowKeys = _union(modifiedCacheKeys, modifiedNewKeys);
  const flattedModified = _pick(flattenNew, allowKeys);
  
  const modifiedValues = unflatten(flattedModified);
  const newValues = _transform(
    modifiedValues,
    (result, value, key) => (result[key] = (_isObject(value) || _isArray(value)) ? newObject[key] : value),
    {},
  );
  return _assignIn({}, cachedObject, newValues);
};

export const mergeObjectRecursive = (cachedObject, newObject) => (
  Object.entries(newObject)
    .reduce((prevCache, [key, newValue]) => {
      try {
        if (!_isNil(prevCache[key]) || _isArray(prevCache)) {
          prevCache[key] = ((newValue.constructor === Object) || (_isArray(newValue) && newValue.length > 0))
            ? mergeObjectRecursive(prevCache[key], newValue)
            : newValue;
        }
      } catch (e) {
        prevCache = { ...prevCache, [key]: newValue };
      }
      return prevCache;
    }, cachedObject)
);

export default {
  cleanGraph,
  loadMoreFromQuery,
  onCatchError,
  updateCachedObject,
  updateCachedObject2,
  mergeObjectRecursive,
};